<template>
  <div class="certificateView">
    <div class="bottom_icon">
      <div class="bottom_icon_top">
        <p class="bottom_icon_top_dian" />
      </div>
      <p v-if="activeIndex < datailList.length - 1" class="bottom_icon_txt">
        向下滑动
      </p>
      <p v-else class="bottom_icon_txt">向上滑动</p>
    </div>
    <navigation3 class="navigation3" :active-index="(activeIndex + 1).toString()" :is-index="false"
      :menu-list="menuList" @setActiveItem="setActiveItem" @selectChange="selectChange" />
    <div class="timeLine_box">
      <div v-for="item in datailList.length" :key="item" :class="[
        item == activeIndex + 1 ? 'active' : '',
        item == datailList.length && item == activeIndex + 1 ? 'last' : '',
      ]" class="timeLine_box_item" @mouseenter="setActiveItem(item - 1)">
        <el-image v-if="item == activeIndex + 1" :src="require(`@/assets/img/zige/dian.png`)" />
        <div v-if="item == activeIndex + 1" class="timeLine_box_item_line" />
      </div>
    </div>
    <div class="tips_box">
      <div ref="popuptel" class="popuptel">
        {{ domainInfo.mobile }}
        <div class="triangle tel" />
      </div>
      <div ref="popup1" class="popuptel1">
        <el-image class="img" :src="domainInfo.wechatMpName" v-if="domainInfo.wechatMpName"></el-image>
        <p class="img_p" v-else>暂无图片</p>
        <div class="triangle tel" />
      </div>
      <div ref="popup" class="popuptel2">
        {{ domainInfo.mobile }}
        <div class="triangle tel" />
      </div>
      <div class="item">
        <el-image class="tips_box_item" :src="require(`@/assets/img/zige/r1.png`)" alt="" @mouseenter="popup(1)"
          @mouseleave="popdown(1)" />
        <el-image class="tips_box_item" :src="require(`@/assets/img/zige/r2.png`)" alt="" @mouseenter="popup(2)"
          @mouseleave="popdown(2)" />
        <el-image class="tips_box_item" :src="require(`@/assets/img/zige/r3.png`)" alt="" @mouseenter="popup(3)"
          @mouseleave="popdown(3)" />
      </div>
    </div>
    <div>
      <el-carousel ref="following" direction="vertical" :initial-index="Number(activeIndex)" :autoplay="false"
        indicator-position="none" @change="scrollActive">
        <el-carousel-item v-for="(item, index) in datailList" ref="following_item" :key="index"
          :class="isjump ? 'is-anActive' : ''">
          <div v-if="item.classifName != '新闻中心'" class="following_item" :class="`bac${index}`">
            <div class="following_item_blur" :class="`blur${index}`" />
            <div class="following_item_active">
              <div class="following_item_letter">{{ item.letter }}</div>
              <div class="following_item_title">{{ item.classifName }}</div>
              <div class="following_item_line" />
              <div class="following_item_content">{{ item.tip }}</div>
              <el-button class="following_item_btn" @click="goNext(`/agent/scroll?id=${item.id}`)"
                v-if="item.introduce === 'true'">
                <div>
                  <span>了解更多 </span>
                  <el-image :src="require(`@/assets/img/zige/arrow.png`)" />
                </div>
              </el-button>
            </div>
          </div>
          <div v-else class="following_item following_items" :class="`bac${index}`">
            <div class="following_item_blur" :class="`blur${index}`" />
            <div class="following_item_actives">

              <div class="new">
                <div class="new_left">
                  <div class="new_til">
                    <div>NEW</div>
                    新闻中心
                  </div>
                  <div class="new_left_bot">
                    <div :class="projectTypeId == items.id ? 'new_left_bot_choose' : ''
        " v-for="(items, indexs) in datailLists" :key="indexs" @mouseenter="getInformations(items)">
                      {{ items.classifName }}
                    </div>
                  </div>
                </div>
                <div class="new_right">
                  <div @click="
        goNext(
          `/zgznewsxq?id=${items.id}&projectTypeId=${projectTypeId}`
        )
        " v-for="(items, indexs) in informationList" :key="indexs">
                    <div class="information_img">
                      <img :src="items.img" alt="" />
                    </div>
                    <div class="information_til elp">{{ items.title }}</div>
                    <div v-html="items.introduction" class="information_tils twoEllipsis"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <!-- <el-carousel-item ref="following_item" :class="isjump ? 'is-anActive' : ''">
          <div class="following_item" :class="`bac${index}`"></div>
       
        </el-carousel-item> -->
      </el-carousel>
    </div>
  </div>
</template>

<script>
import {
  getProjectClassList,
  getProjectClassLis,
  selectInformationPcList,
} from "@/api/home";
import { getDomain } from "@/api/cookies";
import navigation3 from "./components/navigation3.vue";
export default {
  components: {
    navigation3,
  },
  data() {
    return {
      is_huadong: true,
      projectTypeId: "",
      flag: true,
      activeIndex: "0",
      domainInfo: "",
      menuList: [],
      isjump: false,
      datailList: [],
      datailLists: [],
      informationList: [],
      tipList: [
        {
          classifName: "",
        },
      ],
    };
  },
  created() {
    this.detail_id = this.$route.query.id;
    this.activeIndex = this.$route.query.id ?? "0";
    this.getList();
    this.getdetailList();
    this.domainInfo = getDomain();
  },
  mounted() {
    if (navigator.userAgent.indexOf("Firefox") > -1) {
      document.addEventListener("DOMMouseScroll", (e) => {
        if (this.flag) {
          setTimeout(() => {
            //   // 滚动条滚动的距离
            this.isjump = false;
            if (e.detail > 0 && this.activeIndex + 1 < this.datailList.length) {
              this.$refs.following.next();
              this.is_huadong = true;
            } else if (e.detail < 0 && this.activeIndex > 0) {
              this.$refs.following.prev();
              this.is_huadong = true;
            }
            this.flag = true;
          }, 300);
        }
        this.flag = false;
      });
    } else {
      window.onmousewheel = document.onmousewheel = (e) => {
        if (this.flag) {
          setTimeout(() => {
            // 滚动条滚动的距离
            this.isjump = false;
            if (
              e.wheelDelta < 0 &&
              this.activeIndex + 1 < this.datailList.length
            ) {
              this.$refs.following.next();
              this.is_huadong = true;
            } else if (e.wheelDelta > 0 && this.activeIndex > 0) {
              this.$refs.following.prev();
              this.is_huadong = true;
            }
            this.flag = true;
          }, 300);
        }
        this.flag = false;
      };
    }
    if (this.detail_id || this.detail_id == 0) {
      this.$nextTick(() => {
        this.setActiveItem(this.detail_id);
      });
    }
  },
  methods: {
    selectChange(val) {
      this.activeIndex = val;
    },
    popup(item) {
      if (item == 1) {
        this.$refs.popup.style.display = "block";
      }
      if (item == 2) {
        this.$refs.popup1.style.display = "block";
      }
      if (item == 3) {
        this.$refs.popuptel.style.display = "block";
      }
    },
    popdown(item) {
      if (item == 1) {
        this.$refs.popup.style.display = "none";
      }
      if (item == 2) {
        this.$refs.popup1.style.display = "none";
      }
      if (item == 3) {
        this.$refs.popuptel.style.display = "none";
      }
    },
    setActiveItem(e) {
      this.activeIndex = e;
      this.isjump = true;
      this.$nextTick(() => {
        this.$refs.following.setActiveItem(e);
      });
      this.$forceUpdate();
    },
    scrollActive(e) {
      this.activeIndex = e;
    },
    async getList() {
      const params = {
        type: 2,
        status: 1,
      };
      const res = await getProjectClassList(params);
      if (res.code == 0) {
        this.menuList = res.data;
      }
    },
    async getdetailList() {
      const res = await getProjectClassLis({
        type: "2",
        status: "1",
      });
      if (res) {
        this.datailLists = JSON.parse(JSON.stringify(res.data));

        this.datailList = res.data;
        this.datailList.forEach((e, ind) => {
          if (e.classifName.includes("准入类")) {
            e.letter = "ADMITTANCE CLASS";
            e.tip =
              "准入类职业资格是指由国务院劳动、人事行政部门通过学历认定、资格考试、专家评定、职业技能鉴定等方式进行评价，对合格者授予的国家职业资格证书。准入类职业资格是对从事某一职业所必备的学识、技术和能力的基本要求。";
          } else if (e.classifName.includes("水平评价类")) {
            e.letter = "LEVEL ASSESSMENT CLASS";
            e.tip = `为涉及的职业工种具有较强的专业性和社会通用性，劳动者可以自愿参加评价。
                   水平评价类主要为涉及的职业工种具有较强的专业性和社会通用性，技术技能要求较高，行业管理和人才队伍确实需要，劳动者可以自愿参加评价。`;
          } else if (e.classifName.includes("职业技能")) {
            e.letter = "VOCATIONAL SKILL";
            e.tip =
              "职业技能等级证书是职业技能鉴定机构按照国家职业技能标准和行业企业评价规范，对劳动者进行职业技能等级认定，对合格人员颁发职业技能等级证书。";
          } else if (e.classifName.includes("专项能力证书")) {
            e.letter = "SPECIAL CAPABILITY";
            e.tip = `专项职业能力证书是劳动者熟练掌握并应用某项实用职业技能的证明，表明劳动者具备了从事某项职业岗位所必需的基本能力。
                     专项职业能力证书是由省、市人社部门（或经省、市人社部门审核同意的考核机构）依据专项职业能力考核规范，按照相关程序组织考核，对考核合格者，由省、市人社部门颁发相应的专项职业能力证书。`;
          } else if (e.classifName.includes("行业协会、社会组织评价类")) {
            e.letter = "TRADE ASSOCIATION";
            e.tip =
              "行业协会是指介于政府、企业之间，商品生产者与经营者之间，并为其服务、咨询、沟通、监督、公正、自律、协调的社会中介组织。行业协会是一种民间性组织，它不属于政府的管理机构系列，而是政府与企业的桥梁和纽带。";
          }
        });
        this.datailList.push({ classifName: "新闻中心", letter: "NEW" });
      }
      if (this.datailLists && this.datailLists.length > 0) {
        console.log(this.datailLists[0], "this.datailLists[0]");
        await this.getInformations(this.datailLists[0]);
      }
    },
    async getInformations(item) {
      console.log(item, "item");
      this.projectTypeId = item.id;
      const res = await selectInformationPcList({
        projectTypeId: item.id,
        pageSize: 6,
        pageNum: 1,
      });
      this.informationList = res.rows;
    },
  },
};
</script>

<style lang="less" scoped>
.bac0 {
  height: 100vh;
  background-image: url("~@/assets/img/zige/bac1.jpg");
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: linear-gradient(135deg,
      #33041a 0%,
      rgba(51, 4, 26, 0.7) 100%);
  border-radius: 0px 0px 0px 0px;
}

.bac1 {
  height: 100vh;
  background-image: url("~@/assets/img/zige/bac2.jpg");
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

.bac2 {
  height: 100vh;
  background-image: url("~@/assets/img/zige/bac3.jpg");
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

.bac3 {
  height: 100vh;
  background-image: url("~@/assets/img/zige/bac4.jpg");
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

.bac4 {
  height: 100vh;
  background-image: url("~@/assets/img/zige/bac5.jpg");
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

.bac5 {
  height: 100vh;
  background-image: url("~@/assets/img/zige/bac6.png");
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

/deep/ .is-animating {
  transition: transform 0.3s ease !important;
}

/deep/ .is-anActive {
  transition: transform 0.4ms ease !important;
}

/deep/ .el-carousel__container {
  width: 100%;
  height: 100vh;

  .following_item {
    position: relative;
    height: 100% !important;
    padding-left: 359px;
    padding-top: 286px;

    // background-color: darkgreen;
    .following_item_blur {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1;
      height: 100%;
      // backdrop-filter: blur(20px);
    }

    .blur0 {
      background-image: linear-gradient(to bottom right,
          rgba(4, 42, 57, 0.7),
          rgba(4, 42, 57, 0.6));
    }

    .blur1 {
      background-image: linear-gradient(to bottom right,
          rgba(30, 54, 81, 0.7),
          rgba(30, 54, 81, 0.6));
    }

    .blur2 {
      background-image: linear-gradient(to bottom right,
          rgba(51, 4, 26, 0.7),
          rgba(51, 4, 26, 0.6));
    }

    .blur3 {
      background-image: linear-gradient(to bottom right,
          rgba(4, 20, 57, 0.7),
          rgba(4, 20, 57, 0.6));
    }

    .blur4 {
      background-image: linear-gradient(to bottom right,
          rgba(4, 49, 19, 0.7),
          rgba(4, 49, 19, 0.6));
    }

    .blur5 {

      background-image: linear-gradient(to bottom right,
          rgba(31, 37, 44, 0.6),
          rgba(31, 37, 44, 0.7));
    }

    .following_item_active {
      position: absolute;
      top: 0;
      padding-left: 359px;
      padding-top: 286px;
      left: 0;
      width: 100%;
      z-index: 2;
      height: 100%;

      .following_item_letter {
        position: absolute;
        opacity: 0.2;
        top: 275px;
        font-size: 50px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 55px;
        letter-spacing: 5px;
      }

      .following_item_title {
        font-size: 50px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 80px;
        letter-spacing: 5px;
      }

      .following_item_line {
        width: 102px;
        height: 4px;
        background: #ffffff;
        opacity: 1;
        margin: 30px 0;
      }

      .following_item_content {
        width: 1099px;
        height: 104px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
        letter-spacing: 1.8px;
      }

      .following_item_btn {
        width: 160px;
        z-index: 999;
        margin-top: 71px;
        height: 54px;
        border: none;
        background: #fba627;
        border-radius: 29px 29px 29px 29px;
        opacity: 1;

        div {
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            display: inline-block;
            margin-right: 10px;
          }

          img {
            width: 18px;
            height: 12px;
            color: #333333;
          }
        }
      }
    }

    .following_item_actives {
      position: absolute;
      top: 0;
      // padding-left: 359px;
      padding-top: 186px;
      left: 0;
      width: 100%;
      z-index: 2;
      height: 100%;
    }
  }

  .following_items {
    padding-left: 0;
    padding-top: 226px;
    position: relative;
    height: 100% !important;

    // width: 1200px;
    // margin: 0 auto;
    .following_item_blur {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1;
      height: 100%;
      // backdrop-filter: blur(20px);
    }
  }
}

.navigation3 {
  position: fixed;
  background-color: rgba(255, 255, 255, 0);
}

.certificateView {
  position: relative;

  .bottom_icon {
    position: absolute;
    left: 50%;
    bottom: 50px;
    transform: translateX(-50%);
    z-index: 98;
    display: flex;
    align-items: center;
    flex-direction: column;

    .bottom_icon_top {
      width: 30px;
      height: 44px;
      border-radius: 15px 15px 15px 15px;
      opacity: 1;
      border: 2px solid #ffffff;

      .bottom_icon_top_dian {
        width: 2px;
        height: 8px;
        background: #ffffff;
        border-radius: 1px 1px 1px 1px;
        opacity: 1;
        position: absolute;
        left: 50%;
        bottom: 45px;
        transform: translateX(-50%);
        // z-index: 999999;
      }

      .bottom_icon_top_dian_hua {
        animation: moveDownUp 1s ease-in-out infinite;
      }

      @keyframes moveDownUp {

        0%,
        100% {
          transform: translateY(0);
        }

        50% {
          transform: translateY(10px);
        }
      }
    }

    .bottom_icon_txt {
      width: 56px;
      height: 19px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #fff;
      line-height: 40px;
    }
  }

  height: 100vh;

  // overflow: scroll;
  .timeLine_box {
    position: fixed;
    z-index: 999;
    left: 51px;
    top: 345px;

    .timeLine_box_item {
      position: relative;
      width: 12px;
      cursor: pointer;
      height: 12px;
      background: #ffffff;
      opacity: 0.5;
      margin: 20px 0;
      border-radius: 50%;

      img {
        width: 34px;
        height: 34px;
        // background-size: contain;
      }

      .timeLine_box_item_line {
        top: 50px;
        left: 15px;
        position: absolute;
        width: 2px;
        height: 30px;
        background: #ffffff;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
    }

    // .timeLine_box_item:nth-last-child(1){
    //   .timeLine_box_item_line{
    //     height: 0;
    //   }
    // }
    .active {
      width: 34px;
      margin-bottom: 63px;
      height: 34px;
      opacity: 1;
      transform: translateX(-10px);
      background: none;
      // border: 1px solid #ffffff;
    }

    .last {
      margin-top: 63px;

      .timeLine_box_item_line {
        top: -43px;
      }
    }
  }

  .tips_box {
    position: fixed;
    z-index: 999;
    right: 58px;
    margin-right: -24px;
    top: 345px;

    .popup {
      position: absolute;
      display: none;
      right: 68px;
      top: 63px;
      width: 150px;
      height: 150px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
    }

    .popuptel {
      position: absolute;
      display: none;
      right: 68px;
      top: 135px;
      width: 150px;
      height: 54px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      line-height: 54px;
      padding-left: 17px;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }

    .popuptel1 {
      position: absolute;
      display: none;
      right: 68px;
      top: 65px;
      width: 150px;
      height: 150px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      line-height: 54px;
      padding-left: 17px;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;

      .img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 130px;
        height: 130px;
        margin-top: -65px;
        margin-left: -65px;
      }

      .img_p {
        text-align: center;
        line-height: 150px;
        margin-left: -5px;
      }
    }

    .popuptel2 {
      position: absolute;
      display: none;
      right: 68px;
      top: 0px;
      width: 150px;
      height: 54px;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      line-height: 54px;
      padding-left: 17px;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }

    .triangle {
      position: absolute;
      right: -11px;
      top: 11px;
      width: 0;
      height: 0;
      border-left: 14px solid #fff;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
    }

    .tel {
      top: 20px;
    }

    .item {
      display: flex;
      flex-direction: column;
    }

    .tips_box_item {
      width: 48px;
      height: 48px;
      border-radius: 24px 24px 24px 24px;
      opacity: 1;
      margin-bottom: 20px;
      border: 1px solid #ffffff;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.new {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;


  .new_left {
    width: 240px;

    .new_til {
      position: relative;

      font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: bold;
      font-size: 50px;
      color: #ffffff;
      text-align: left;
      font-style: normal;
      text-transform: none;
      top: -20px;
      margin-bottom: 40px;

      >div {
        position: absolute;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 50px;
        color: #ffffff;
        line-height: 55px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        top: -26px;
        opacity: 0.2;
      }
    }

    .new_left_bot {
      overflow-y: scroll;
      height: 400px;

      >div {
        width: 224px;
        height: 54px;
        border-radius: 6px 6px 6px 6px;
        border: 1px solid #989CA3;

        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        text-align: left;
        font-style: normal;
        text-transform: none;
        padding: 16px;
        margin-bottom: 20px;
        cursor: pointer;
      }

      .new_left_bot_choose {
        color: #000000;
        background: #fba627;
        border-radius: 6px 6px 6px 6px;
        border: 0;
      }
    }
  }

  .new_right {
    width: 940px;
    display: flex;
    flex-wrap: wrap;


    >div {
      width: 288px;
      margin-right: 30px;
      margin-bottom: 60px;
      cursor: pointer;
      transition: transform 1s ease;

      .information_img {
        width: 288px;
        height: 162px;
        display: block;
        border-radius: 6px;

        img {

          border-radius: 6px;
        }
      }

      .information_til {
        width: 254px;
        height: 21px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 16px;
        color: #ffffff;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-top: 20px;

      }

      .information_tils {
        line-height: 26px;
        width: 280px;
        // height: 45px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-top: 10px;
        opacity: 0.8;
      }
    }

    >div:hover {
      transform: scale(1.1);
    }

    >div:nth-child(3n) {
      margin-right: 0;
    }
  }
}

/* 设置滚动条轨道的样式 */
::-webkit-scrollbar-track {
  // background: transparent;
  border-radius: 10px;
  // border: 1px solid #ccc;
}

/* 设置滚动条的样式 */
::-webkit-scrollbar-thumb {
  background-color: #ccc;
  // border: 1px solid #ccc;
}

// ::-webkit-scrollbar {
//   width: 6px;
//   border-radius: 10px;
//   // background-color: #ccc;
//   // background: transparent;
// }

img {
  width: 100%;
  height: 100%;
  display: block;
}
</style>
